import { useQuery } from "@tanstack/react-query";
import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { useMutation } from "@tanstack/react-query";
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from "dayjs";
import { getBlogFinderQuery, blogFinderMutation, downloadCsv } from "../../utils/api";
import "./BlogFinder.scss";
import AbunTable from "../../components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { CountryType } from "../../utils/constants/CountriesforSerp";
import Papa, { ParseResult } from 'papaparse';
import uploadIcon from "../../assets/images/icons/cloud-upload.svg";
import Drawer from "../../assets/images/Drawer-Download--Streamline-Ultimate.png";

export interface BlogFinderTableProps{
    blogUrl: string,
    isValid: boolean,
    authorName: string,
    emailAddress: string,
    createdOn: string
}

interface BlogFinderBulkTableProps{
    blogProjectName: string,
    blogProjectId: string,
    createdOn: string
}

const BlogFinder = () => {
    const navigate = useNavigate();
    
    const [blogUrls, setBlogUrls] = useState<string>("");
	const [columns, setColumns] = useState<Array<string>>([]);
    const [tableData, setTableData] = useState<BlogFinderTableProps[]>([]);
    const [columnIndex, setColumnIndex] = useState(0);
    const fileInput = useRef<HTMLInputElement>(null);
    const [selectedTab, setSelectedTab] = useState("Input Field");
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);
    const [file, setFile] = useState<File | null>(null);
    const [valid, setValid] = useState(false)
    const [Loading, setLoading] = useState(false)
    const pageSizes = [5, 10, 15, 30, 50, 100, 500];
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
    const [isCopied, setIsCopied] = useState<Boolean>(false);
    const [downloadProjectId, setDownloadProjectId] = useState("");
    const [downloadProjectName, setDownloadProjectName] = useState("");
    const [selectedLocation, setSelectedLocation] = useState<CountryType>({
            location_code: 1,
            location_name: 'Global',
            country_iso_code: 'ZZ',
        });

        const { data: csvData, isFetching: isFetchingCsv, error: csvError, refetch: refetchCsv } = useQuery({
          queryKey: ['downloadcsvData', downloadProjectId],
          queryFn: () => downloadCsv({ projectId: downloadProjectId }),
          enabled: !!downloadProjectId, 
          cacheTime: 0,
          refetchOnWindowFocus: false,
        });
      
        useEffect(() => {
          if (csvData && downloadProjectId) {
            const csv = convertToCSV(csvData?.data?.data || []);
            triggerDownload(csv);
            setDownloadProjectId('');
          }
        }, [csvData]);
      
        function convertToCSV(data) {
          const header = ['blogUrl', 'isValid', 'authorName', 'emailAddress', 'createdOn'];
          const rows = data.map((item) => [
            item.blogUrl || '',
            item.isValid !== undefined ? item.isValid : '',
            item.authorName || '',
            item.emailAddress || '',
            new Date(item.createdOn).toISOString() || '',
          ]);
          return [header.join(','), ...rows.map((row) => row.join(','))].join('\n');
        }
      
        function triggerDownload(csv) {
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          link.download = `${downloadProjectName}_${downloadProjectId}.csv`;
          link.href = URL.createObjectURL(blob);
          link.click();
        }
      
        async function handleDownloadClick(blogProjectId,blogProjectName) {
          setDownloadProjectId(blogProjectId);
          setDownloadProjectName(blogProjectName)
          refetchCsv();
        }
    
    
    const blogFinder = useMutation(blogFinderMutation);

    const handleBackClick = () => {
        navigate('/');
    };


    const handleMouseEnter = (rowId: string) => {
        setHoveredRowId(rowId);
    };

    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const handleCopy = (email: string) => {
        navigator.clipboard.writeText(email);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 2000);
    };
    
    const handlePatternChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setBlogUrls(value); // Update the state
        if (value.trim()) {
            setValid(true);
        } else {
            setValid(false);
        }
    };
    const controller = new AbortController();
    const signal = controller.signal;

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        setLoading(true)
        setValid(false)
        const formData = new FormData();
        if (blogUrls) formData.append("blog_urls", blogUrls);
        if (file) formData.append("file", file);
        if (selectedLocation) {
            formData.append("selectedLocation", JSON.stringify(selectedLocation));
        }
        if (columns) formData.append("header", columns[columnIndex]) 
        blogFinder.mutate(
            { formData, signal },
            {   
                onSuccess: () => {
                    if (signal.aborted) return;
                    setLoading(false)
                    setBlogUrls("")
                    refetch()
                    successAlertRef.current?.show("Generated Blog URL data!");
                    setValid(true)
                },
                onError: (error: any) => {
                    if (signal.aborted) return;
                    setLoading(false)
                    setValid(true)
                    setBlogUrls("")
                    const errorMessage = error?.response?.data?.message || "Failed to generate Blog URL data";
                    errorAlertRef.current?.show(errorMessage);
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 3000);
                },
            }
        );
    }

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
            if (e.target.files) {
                let file = e.target.files[0]
                if (file) {
                    setFile(file);
                    setValid(true);
                    Papa.parse((file as any), {
                        complete(results: ParseResult<any>) {
                            let data = results.data; // All rows
                            let columns: string[] = [];

                            if (data.length > 0 && data[0].some((cell: any) => cell.trim() !== "")) {
                                columns = data[0]; 
                                setColumns(columns);
                            } else {
                                columns = []; 
                                setColumns(columns);
                            }
                        }
                    })
                }else{
                    setFile(null);
                    setValid(false);
                    setColumns([])
                }                
            }
        }

    const columnHelper = createColumnHelper<BlogFinderTableProps>();
    const blogcolumnHelper = createColumnHelper<BlogFinderBulkTableProps>();
    const columnDefs: ColumnDef<any, any>[] = selectedTab === "Input Field" ? ([
        columnHelper.accessor((row: BlogFinderTableProps) => row.authorName, {
            id: 'authorName',
            header:"Author Name",
            cell: props => {
                
                if (props.row.original.authorName){
                    return (
                        <span>{props.row.original.authorName}</span>
                    )}
                }}),
                columnHelper.display({
                    id: 'emailAddress',
                    header: "Email Address",
                    cell: props => {
                        const rowId = props.row.id;
        
                        if (props.row.original.emailAddress) {
                            return (
                                <div
                                    style={{ position: 'relative', display: 'inline-block' }}
                                    onClick={() => handleCopy(props.row.original.emailAddress)}
                                    onMouseEnter={() => handleMouseEnter(rowId)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <span>{props.row.original.emailAddress}</span>
                                    {hoveredRowId === rowId && (
                                        <button
                                            onClick={() => handleCopy(props.row.original.emailAddress)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '100%',
                                                transform: 'translate(0, -50%)',
                                                backgroundColor: '#ffffff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '5px',
                                                cursor: 'pointer',
                                                zIndex: 10,
                                            }}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#8fbffa" className="bi bi-copy" viewBox="0 0 16 16" id="Copy--Streamline-Bootstrap" height="16" width="16" style={{marginRight: '3px',}}><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2H6a2 2 0 0 1 -2 -2zm2 -1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1 -1V2a1 1 0 0 0 -1 -1zM2 5a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1 -1v-1h1v1a2 2 0 0 1 -2 2H2a2 2 0 0 1 -2 -2V6a2 2 0 0 1 2 -2h1v1z" stroke-width="1"></path></svg>
                                        {isCopied ? 'Copied!' : 'Copy'}
                                        </button>
                                    )}
                                </div>
                            );
                        }
                        return null;
                    },
                }),
            columnHelper.display({
                id: 'createdOn',
                header: "Created On",
                cell: props => {
                    if (props.row.original.createdOn) {
                        return (
                            <span>{dayjs(props.row.original.createdOn).format("hh:mma DD MMMM YYYY")}</span>
                        );
                    }
                },
            }),
            columnHelper.display({
                id: 'blogUrls',
                header: "Blog Urls",
                cell: props => {
                    if (props.row.original.blogUrl) {
                        return (
                            <span
                            onClick={() => {
                                window.open(props.row.original.blogUrl, '_blank');
                            }}>
                                {props.row.original.blogUrl}
                            </span>                                
                        );
                    }
                },
            }),
        ]):([
        blogcolumnHelper.accessor((row: BlogFinderBulkTableProps) => row.blogProjectName, {
            id: 'blogProjectName',
            header:"Blog Project",
            cell: props => {
                const handleClick = () => {
                    const { blogProjectId } = props.row.original;
                    navigate(`/blog-urls-table/${blogProjectId}/${props.row.original.blogProjectName}`);
                  };
                if (props.row.original.blogProjectName){
                    return (
                        <span onClick={handleClick} style={{ cursor: 'pointer' }}>{props.row.original.blogProjectName}</span>
                    )}
                }}),
                blogcolumnHelper.display({
                    id: 'createdOn',
                    header: "Created On",
                    cell: props => {
                        if (props.row.original.createdOn) {
                            return (
                                <span>{dayjs(props.row.original.createdOn).format("hh:mma DD MMMM YYYY")}</span>
                            );
                        }
                    },
                }),
                blogcolumnHelper.display({
                    id: 'action',
                    header: "Action",
                    cell: props => {
                        if (props.row.original.blogProjectName) {
                            return (
                                <img className="download-url-icon ml-4"  src={Drawer} alt="upload-icon" onClick={() => {handleDownloadClick(props.row.original.blogProjectId,props.row.original.blogProjectName)}} style={{ cursor: 'pointer', height: '20px'}}/>
                            );
                        }
                    },
                }),
        ])
    
    
        const { isLoading, error, data, refetch } = useQuery(getBlogFinderQuery(selectedTab));
        
        useEffect(() => {
            if (data && !downloadProjectId) {
                setTableData((data as any)['data']['data']);
            }
        }, [data]);

        


    return (
        <div className="ps-container">
                <div className={"seo-project-header"}>
                    <svg className={"back-btn seo-back"} onClick={handleBackClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
                        <path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
                    </svg>
                </div>
                <h1 className="ps-header">Blog Finder</h1>
                <div className="seo-form-container">
                    <form className="seo-form" onSubmit={handleSubmit}>
                        <div className="form-group bottom-space">
                            <div className="blog-finder-input-container">
                            {selectedTab === "Input Field" ? (
                                <TextField
                                    id="urlInput"
                                    label="Enter Blog URLs (one per line)"
                                    variant="outlined"
                                    multiline
                                    rows={Math.min(20, blogUrls.split('\n').length || 1)}
                                    maxRows={20} 
                                    sx={{ width: '80%'}}
                                    value={blogUrls}
                                    onChange={handlePatternChange}
                                />
                        ) : (
                            <div
                                style={{
                                    border: '1px solid #ccc', 
                                    borderRadius: '8px', 
                                    padding: '20px',  
                                    boxShadow: '0 4px 6px hsla(0, 0.00%, 0.00%, 0.10)',
                                    backgroundColor: '#fff',
                                }}
                                className="blog-finder-upload-box"
                            >                            
                            <div className="blog-finder-upload-container"                                 
                                onDragOver={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                            onDrop={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                            
                                                if (e.dataTransfer.items) {
                                                    const file = e.dataTransfer.items[0].getAsFile();
                                                    if (file) {
                                                        setFile(file);
                                                        setValid(true);
                                                        // read using papaparse
                                                        Papa.parse((file as any), {
                                                            complete(results: ParseResult<any>) {
                                                                let columns = results.data[0];
                                                                // let csvData = results.data.splice(1, results.data.length);
                                                                setColumns(columns);
                                                            }
                                                        });
                                                    }else{
                                                        setValid(false);
                                                    }
                                                }
                                            }}
                            >
                                <div className="blog-finder-responsive-content">
                                    <img src={uploadIcon} alt="upload-icon" />
                                    <p>
                                        Click to <b>choose a file</b> or <b>drag it here</b> with a column containing your Blog URLs
                                    </p>
                                </div>
                                <input
                                    type="file"
                                    accept=".csv, .xlsx"
                                    ref={fileInput}
                                    onChange={onFileSelected}
                                    className="upload-input"
                                />                            
                            </div>
                            {columns.length > 0 ? (
                            <>
                            <label className="label">Choose column with Blog url data</label>
                            <div className="select">
                                <select value={columnIndex}
                                    onChange={e => setColumnIndex(parseInt(e.target.value)) }
                                    >
                                    {columns.map((columnName, index) => (
                                        <option value={index} key={`${columnName}-${index}`}>{columnName}</option>
                                    ))}
                                </select>
                            </div>
                            </> ) : <></> }
                            </div>
                        )}
                            </div>
                        <button type="submit" className="submit-btn" disabled={!valid}>
                            Find Author
                        </button>
                        <div id="results" className="result"></div>
                        </div>
                    </form>
                        {Loading && (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "1.3rem",
                                }}
                            >
                                Loading Data...
                                <img
                                    src="/static/media/spinner.a81353c0a4707f9915a4835a359de143.svg"
                                    alt="spinner"
                                    className="spinner-spin ml-5"
                                    style={{
                                        width: "1.4em",
                                        height: "auto",
                                        verticalAlign: "middle",
                                    }}
                                />
                            </p>
                        )}
                <AbunTable tableContentName={selectedTab === "Input Field"? "Blog Urls": "Blog Projects"}
                    tableData={tableData}
                    columnDefs={columnDefs}
                    pageSizes={pageSizes}
                    initialPageSize={pageSizes[4]}
                    noDataText={"No keywords found"}
                    searchboxPlaceholderText={"Search keywords projects..."}
                    selectedTab={selectedTab}
                    filterTabs={[
                        {
                            name: "Input Field",
                            onClick: () => setSelectedTab("Input Field")
                        },
                        {
                            name: "Bulk Upload",
                            onClick: () => setSelectedTab("Bulk Upload")
                        },]}
                />
                <SuccessAlert ref={successAlertRef} />
                <ErrorAlert ref={errorAlertRef} />
                </div>
        </div>
    )
}

export default BlogFinder;