import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import { getGuestPostFinderQueriesView} from "../../utils/api";
import Icon from "../../components/Icon/Icon";

interface QueryResult {
    post_title: string,
    post_link: string,
    query: string,
    hypestat: {
        domain: string;
        organic_traffic: number;
        organic_keywords: number;
        domain_authority: number;
        total_backlinks: number;
        follow: number;
        no_follow: number;
        referring_domains: number;
    },
}


export default function GuestPostFinderView() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];

    // -------------------------- STATES --------------------------
    const [queries, setQueries] = useState<Array<QueryResult>>([]);
    const { queryID } = useParams() as { queryID: string };
    const [query, setQuery] = useState("");
    const navigate = useNavigate();

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch
    } = useQuery({
        queryKey: ['getGuestPostFinderQueriesView', queryID],
        queryFn: () => getGuestPostFinderQueriesView(queryID),
        refetchOnWindowFocus: false
    });

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            // Function to replace null/undefined with "N/A"
            const setDefaults = (item: QueryResult): QueryResult => ({
                post_title: item.post_title || "N/A",
                post_link: item.post_link || "N/A",
                query: item.query || "N/A",
                hypestat: {
                    domain: item.hypestat?.domain || "N/A",
                    organic_traffic: item.hypestat?.organic_traffic ?? "0",
                    organic_keywords: item.hypestat?.organic_keywords ?? "0",
                    domain_authority: item.hypestat?.domain_authority ?? "0",
                    total_backlinks: item.hypestat?.total_backlinks ?? "0",
                    follow: item.hypestat?.follow ?? "0",
                    no_follow: item.hypestat?.no_follow ?? "0",
                    referring_domains: item.hypestat?.referring_domains ?? "0",
                },
            });

            // Transform data
            const transformedQueries = data['data']['queries'].map(setDefaults);

            setQueries(transformedQueries);

            if (transformedQueries.length > 0) {
                setQuery(transformedQueries[0].query);
            }
        }
    }, [data]);

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        document.title = "Guest Post Finder | Abun"
    }, []);

    function formatNumber(value: number): string {
        if (value >= 1_000_000) {
            return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (value >= 1_000) {
            return (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return value.toString();
    }

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<QueryResult>();
    const columnDefs: ColumnDef<any, any>[] = [

        columnHelper.accessor((row: QueryResult) => row.post_title, {
            id: 'URL',
            header: "URL",
            cell: (props) => {
                const handleClick = () => {
                    window.open(props.row.original.post_link, '_blank');
                };

                return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {props.row.original.post_title}
                    </span>
                );
            },
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.domain, {
            id: 'Domain',
            header: "Domain",
            cell: (props) => {
                const handleClick = () => {
                    window.open(`https://${props.row.original.hypestat.domain}`, '_blank');
                };

                return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {props.row.original.hypestat.domain}
                    </span>
                );
            },
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.domain_authority, {
            id: 'Domain Authority',
            header: "Domain Authority",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.organic_traffic, {
            id: 'Organic Traffic',
            header: "Organic Traffic",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.organic_keywords, {
            id: 'Organic Keywords',
            header: "Organic Keywords",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.follow, {
            id: 'Follow',
            header: "Follow",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.no_follow, {
            id: 'No Follow',
            header: "No Follow",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: QueryResult) => row.hypestat.referring_domains, {
            id: 'Referring Domains',
            header: "Referring Domains",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),

        columnHelper.accessor((row: QueryResult) => row.hypestat.total_backlinks, {
            id: 'Total Backlinks',
            header: "Total Backlinks",
            cell: info => formatNumber(info.getValue() || 0),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
    ]
    function goBack() {
        navigate(-1);
    }


    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Find Blogs with Guest Post
                            Opportunities for your Topic/Niche</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <>
                <div className="card w-100">
                    <div className={"card-content"}>
                        <span className={"back-btn"} style={{ cursor: "pointer" }} onClick={goBack}>
                            <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                            </svg>
                        </span>
                        <div className={"w-100 is-flex is-align-items-center is-flex-direction-column mt-3"}>
                            <h1 style={{ fontSize: "2em", fontWeight: "normal" }}>
                                Topic: {query}
                            </h1>
                        </div>

                        <AbunTable tableContentName={"Guest Post Queries"}
                            tableData={queries}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={"No Queries data available."}
                            searchboxPlaceholderText={"Search query..."}
                        />
                    </div>
                </div>
            </>
        );
    }
}
