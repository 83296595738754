import Lottie from "react-lottie";
import animationData from "../../Lottie/abun.json";
import { useRef } from "react";
import { useEffect } from "react";

interface AbunLoaderProps {
    show: boolean;
    height?: string | number;
}

const AbunLoader = ({ show,height = "80vh" }: AbunLoaderProps) => {
    const lottieRef = useRef<any>(null);
    const defaultOptions = {
        loop: true,
        autoplay: true, // Animation will autoplay
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    useEffect(() => {
        if (lottieRef.current?.anim) {
            lottieRef.current.anim.setSpeed(2); // Set animation speed
        }
    }, [show]);

    return (
        <div
            className={`abun-loader ${show ? "visible" : ""}`}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height,
            }}
        >
            <Lottie options={defaultOptions} height={400} width={400} ref={lottieRef} />
        </div>
    );
    
};

export default AbunLoader;
