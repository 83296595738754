import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { downloadCsv } from "../../utils/api";
import "./BlogFinder.scss";
import AbunTable from "../../components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import type { BlogFinderTableProps } from "./BlogFinder"
import { useLoaderData } from "react-router-dom";
import Drawer from "../../assets/images/Drawer-Download--Streamline-Ultimate.png";

const BlogUrlTable = () => { 
    const navigate = useNavigate();
    const pageSizes = [5, 10, 15, 30, 50, 100, 500];
    const [tableData, setTableData] = useState<BlogFinderTableProps[]>([]);
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
    const [isCopied, setIsCopied] = useState<Boolean>(false);
    const [csvClick, setCsvClick] = useState<Boolean>(false);
    const { pageData, params } = useLoaderData() as {
        pageData: { data: BlogFinderTableProps[] };
        params: { blogProjectId: string; blogProjectName: string };
    };

    useEffect(()=>{
        if (pageData){
            setTableData((pageData as any)['data']);
        }
    },[pageData])
    
    const { data: csvData, isFetching: isFetchingCsv, error: csvError, refetch: refetchCsv } = useQuery({
              queryKey: ['downloadcsvData', params.blogProjectId],
              queryFn: () => downloadCsv({ projectId: params.blogProjectId }),
              enabled: !!params.blogProjectId, 
              cacheTime: 0,
              refetchOnWindowFocus: false,
            });
          
    useEffect(() => {
        if (csvData && params.blogProjectId && csvClick) {
            const csv = convertToCSV(csvData?.data?.data || []);
            triggerDownload(csv);
            setCsvClick(false)
        }
    }, [csvData]);
          
    function convertToCSV(data) {
        const header = ['blogUrl', 'isValid', 'authorName', 'emailAddress', 'createdOn'];
        const rows = data.map((item) => [
        item.blogUrl || '',
        item.isValid !== undefined ? item.isValid : '',
        item.authorName || '',
        item.emailAddress || '',
        new Date(item.createdOn).toISOString() || '',
        ]);
        return [header.join(','), ...rows.map((row) => row.join(','))].join('\n');
    }
    
    function triggerDownload(csv) {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.download = `${params.blogProjectId}_${params.blogProjectId}.csv`;
        link.href = URL.createObjectURL(blob);
        link.click();
    }
    
    async function handleDownloadClick() {
        setCsvClick(true)
        refetchCsv();
    }


    const handleMouseEnter = (rowId: string) => {
        setHoveredRowId(rowId);
    };

    const handleMouseLeave = () => {
        setHoveredRowId(null);
    };

    const handleCopy = (email: string) => {
        navigator.clipboard.writeText(email);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000);
    };

    const columnHelper = createColumnHelper<BlogFinderTableProps>();
    const columnDefs: ColumnDef<any, any>[] =[
            columnHelper.accessor((row: BlogFinderTableProps) => row.authorName, {
                id: 'author name',
                header:"Author Name",
                cell: props => {                    
                    if (props.row.original.authorName){
                        return (
                            <span>{props.row.original.authorName}</span>
                        )}
                    }}),
                    columnHelper.display({
                        id: 'emailAddress',
                    header: "Email Address",
                    cell: props => {
                        const rowId = props.row.id;
        
                        if (props.row.original.emailAddress) {
                            return (
                                <div
                                    style={{ position: 'relative', display: 'inline-block' }}
                                    onMouseEnter={() => handleMouseEnter(rowId)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <span>{props.row.original.emailAddress}</span>
                                    {hoveredRowId === rowId && (
                                        <button
                                            onClick={() => handleCopy(props.row.original.emailAddress)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: '#ffffff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '5px 10px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#8fbffa" className="bi bi-copy" viewBox="0 0 16 16" id="Copy--Streamline-Bootstrap" height="16" width="16" style={{ marginRight: '5px' }}><path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2H6a2 2 0 0 1 -2 -2zm2 -1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1 -1V2a1 1 0 0 0 -1 -1zM2 5a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1 -1v-1h1v1a2 2 0 0 1 -2 2H2a2 2 0 0 1 -2 -2V6a2 2 0 0 1 2 -2h1v1z" stroke-width="1"></path></svg>
                                        {isCopied ? 'Copied!' : 'Copy'}
                                        </button>
                                    )}
                                </div>
                            );
                        }
                        return null;
                    },
                }),
                columnHelper.display({
                    id: 'createdOn',
                    header: "Created On",
                    cell: props => {
                        if (props.row.original.createdOn) {
                            return (
                                <span>{dayjs(props.row.original.createdOn).format("hh:mma DD MMMM YYYY")}</span>
                            );
                        }
                    },
                }),
                columnHelper.display({
                    id: 'blogUrls',
                    header: "Blog Urls",
                    cell: props => {
                        if (props.row.original.blogUrl) {
                            return (
                                <span
                                    onClick={() => {
                                        window.open(props.row.original.blogUrl, '_blank');
                                    }}>
                                        {props.row.original.blogUrl}
                                </span>
                            );
                        }
                    },
                }),
            ]
    const handleBackClick = () => {
        navigate('/blog-finder');
    }
    
    return(
        <div className="ps-container">
                <div className={"seo-project-header"}>
                    <svg className={"back-btn seo-back"} onClick={handleBackClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
                        <path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
                    </svg>
                </div>
                <h1 className="ps-header">Blog Urls: <span style={{color:"#3F77F8"}}>{params.blogProjectName}</span> <img className="download-url-icon ml-4" src={Drawer} alt="upload-icon" onClick={() => {handleDownloadClick()}} style={{ cursor: 'pointer', height: '20px'}}/></h1>
                <div className="seo-form-container">
                    <AbunTable tableContentName={"Blog Urls"}
                                tableData={tableData}
                                columnDefs={columnDefs}
                                pageSizes={pageSizes}
                                initialPageSize={pageSizes[4]}
                                noDataText={"No keywords found"}                            />
                </div>
        </div>
    )
}

export default BlogUrlTable;