import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import AbunTable from "../../components/AbunTable/AbunTable";
import Icon from "../../components/Icon/Icon";
import Survey from "../../components/Survey/Survey";
import { SurveyContext } from "../../pages/Login/SurveyContext";
import { pageURL } from "../../pages/routes";
import { getTitleProjectsQuery } from "../../utils/api";
import "./TitleProject.scss";

interface TitleProject {
  keyword_project: string;
  project_id: string;
  total_traffic_volume: number;
  location_iso_code: string;
  keywordHash: string;
  keywordTraffic: number;
  difficultyScore: string;
  count: number;
}

const TitleProject = () => {
  // ----------------------- NON STATE CONSTANTS -----------------------
  const pageSizes = [5, 10, 15, 30, 50, 100, 500];

  // ----------------------- STATE -----------------------
  const [titleProjects, setTitleProjects] = useState<TitleProject[]>([]);

  // ----------------------- QUERY -----------------------
  const { data, isLoading, isFetching, isError } = useQuery(['getSeoTitlesAPI'], getTitleProjectsQuery, {
    staleTime: 0,
    cacheTime: 300000,
    refetchOnWindowFocus: false,
  });

  // -------------------------- SURVEY CONTEXT --------------------------
  const context = useContext(SurveyContext);
  const { showSurvey } = context ? context : { showSurvey: false }

  // ----------------------- NAVIGATE -----------------------
  const navigate = useNavigate();

  // ----------------------- EFFECTS -----------------------
  useEffect(() => {
    if (!isLoading && !isError && data) {
      processData(data);
    }
  }, [data, isLoading, isError]);

  // ----------------------- FUNCTIONS -----------------------
  const processData = (data: any) => {
    if (data?.status === "success" && Array.isArray(data.data)) {
      setTitleProjects(data.data);
    }
  };

  // ----------------------- TABLE -----------------------
  const columnHelper = createColumnHelper<TitleProject>();

  // Define columns
  const columnDefs: ColumnDef<TitleProject, any>[] = [
    columnHelper.accessor((row) => row.keyword_project, {
      id: 'keyword_project',
      header: "SEO Projects",
      cell: (props) => {
        const handleClick = () => {
          const { project_id, keywordHash } = props.row.original;
          navigate(`/list-of-titles/${project_id}/titles/${keywordHash}`);
        };

        return (
          <span onClick={handleClick} style={{ cursor: 'pointer' }}>
            {props.row.original.keyword_project}
          </span>
        );
      }
    }),
    columnHelper.display({
      id: 'total_traffic_volume',
      header: "Total Traffic Volume",
      cell: (props) => (
        <span>{props.row.original.total_traffic_volume || 0}</span>
      ),
    }),
    columnHelper.display({
      id: 'difficultyScore',
      header: "Difficulty",
      cell: (props) => {
        const difficultyScore = parseFloat(props.row.original.difficultyScore);
        let difficulty = 'Very Hard';
        let btn_color = '#8B0000';

        if (difficultyScore < 1000) {
          difficulty = 'Very Easy';
          btn_color = '#6EB173';
        } else if (difficultyScore >= 1000 && difficultyScore < 15000) {
          difficulty = 'Easy';
          btn_color = '#149414';
        } else if (difficultyScore >= 15000 && difficultyScore < 35000) {
          difficulty = 'Moderate';
          btn_color = '#C39C54';
        } else if (difficultyScore >= 35000 && difficultyScore < 100000) {
          difficulty = 'Hard';
          btn_color = '#D22727';
        }

        return (
          <button
            type="button"
            className="button is-small more-rounded-borders custom-btn"
            style={{ backgroundColor: btn_color, color: 'white' }}
          >
            {difficulty}
          </button>
        );
      },
    }),
    columnHelper.display({
      id: 'count',
      header: "Total Articles",
      cell: (props) => (
        <span>{props.row.original.count || 0}</span>
      ),
      meta: {
				align: 'center'
			}
    }),
  ];


  return (
    <>
      {showSurvey && <Survey />}
      <div className="tp-container">
        <div className={"seo-project-header"}>
        </div>
        <h1>Create Programmatic SEO Project</h1>
        <Link to={pageURL['programmaticSeo']}>
          <div className={"menu-btns AI-keyword-research-btn"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
              <g>
                <path id="Subtract" fill="#ffffff" d="M8.775266666666667 6.45940625C6.912027083333334 7.2288041666666665 4.91625 8.039314583333333 3.5038104166666666 8.576460416666668L4.593962500000001 12.644824999999999C6.08565625 12.40375625 8.219385416666668 12.107775 10.217654166666666 11.842508333333335C10.016020833333332 11.258068750000001 9.754012500000002 10.407308333333333 9.422716666666666 9.170722916666668C9.091325 7.934089583333334 8.892854166666668 7.066366666666666 8.775266666666667 6.45940625Z" strokeWidth="1"></path>
                <path id="hover" fill="#ffffff" d="M8.710387500000001 6.10549375C8.597879166666667 5.446160416666666 8.939045833333333 4.901827083333334 9.53191875 4.5922375C11.64145 3.4905375000000003 14.51831875 2.2307604166666666 17.743972916666667 1.2374479166666668C18.68706875 0.947025 19.67223541666667 1.4303604166666668 19.99275 2.3636333333333335C20.286527083333333 3.219329166666667 20.66938125 4.433345833333334 21.100295833333334 6.04166875C21.531258333333337 7.64994375 21.806731250000002 8.892758333333335 21.980093749999998 9.780702083333333C22.16926875 10.74919375 21.557708333333334 11.660377083333335 20.59578125 11.880410416666667C17.305583333333335 12.632989583333334 14.184195833333334 12.980433333333334 11.806522916666667 13.081106250000001C11.138277083333334 13.109425 10.57065625 12.808604166666669 10.338404166666667 12.181375000000001C10.124456250000001 11.603547916666667 9.824545833333334 10.671185416666667 9.422525 9.170722916666668S8.814031250000001 6.7128854166666665 8.710387500000001 6.10549375Z" strokeWidth="1"></path>
                <path id="hover" fill="#ffffff" d="M3.3843541666666668 8.177793750000001C3.230829166666667 7.67495625 2.7970875 7.338533333333334 2.2819833333333337 7.443902083333334C2.1785312500000003 7.465129166666666 2.0650645833333336 7.491675000000001 1.94134375 7.5248333333333335C1.8175750000000002 7.55794375 1.7060250000000001 7.591725 1.6058791666666667 7.625122916666666C1.1070666666666666 7.791393750000001 0.8996354166666667 8.299597916666668 1.0181333333333333 8.811875C1.1355770833333334 9.319839583333334 1.3277229166666669 10.100689583333333 1.6375041666666668 11.256775000000001C1.9472854166666669 12.412908333333334 2.171295833333333 13.185181250000001 2.323575 13.683850000000001C2.4771 14.186735416666666 2.9108416666666668 14.523158333333335 3.4259458333333335 14.417741666666668C3.529397916666667 14.396610416666668 3.6428645833333335 14.370016666666666 3.7665854166666666 14.336858333333334C3.8903541666666666 14.3037 4.001904166666667 14.269966666666667 4.10205 14.23656875C4.600910416666667 14.070297916666668 4.808293750000001 13.56209375 4.689795833333333 13.04976875C4.572352083333333 12.541804166666667 4.380158333333333 11.761002083333333 4.070425 10.604868750000001C3.7606437500000003 9.448783333333335 3.5366333333333335 8.676510416666668 3.3843541666666668 8.177793750000001Z" strokeWidth="1"></path>
                <path id="Rectangle 1097" stroke="#2859c5" strokeLinejoin="round" d="M8.710387500000001 6.10549375C8.597879166666667 5.446160416666666 8.939045833333333 4.901827083333334 9.53191875 4.5922375C11.64145 3.4905375000000003 14.51831875 2.2307604166666666 17.743972916666667 1.2374479166666668C18.68706875 0.947025 19.67223541666667 1.4303604166666668 19.99275 2.3636333333333335C20.286527083333333 3.219329166666667 20.66938125 4.433345833333334 21.100295833333334 6.04166875C21.531258333333337 7.64994375 21.806731250000002 8.892758333333335 21.980093749999998 9.780702083333333C22.16926875 10.74919375 21.557708333333334 11.660377083333335 20.59578125 11.880410416666667C17.305583333333335 12.632989583333334 14.184195833333334 12.980433333333334 11.806522916666667 13.081106250000001C11.138277083333334 13.109425 10.57065625 12.808604166666669 10.338404166666667 12.181375000000001C10.124456250000001 11.603547916666667 9.824545833333334 10.671185416666667 9.422525 9.170722916666668S8.814031250000001 6.7128854166666665 8.710387500000001 6.10549375Z" strokeWidth="1"></path>
                <path id="Subtract_2" stroke="#2859c5" strokeLinejoin="round" d="M8.775266666666667 6.45940625C6.912027083333334 7.2288041666666665 4.91625 8.039314583333333 3.5038104166666666 8.576460416666668" strokeWidth="1"></path>
                <path id="Subtract_3" stroke="#2859c5" strokeLinejoin="round" d="M4.593962500000001 12.644920833333334C6.085704166666667 12.4039 8.219385416666668 12.10791875 10.217654166666666 11.842604166666668" strokeWidth="1"></path>
                <path id="Rectangle 135" stroke="#2859c5" strokeLinejoin="round" d="M3.3843541666666668 8.177793750000001C3.230829166666667 7.67495625 2.7970875 7.338533333333334 2.2819833333333337 7.443902083333334C2.1785312500000003 7.465129166666666 2.0650645833333336 7.491675000000001 1.94134375 7.5248333333333335C1.8175750000000002 7.55794375 1.7060250000000001 7.591725 1.6058791666666667 7.625122916666666C1.1070666666666666 7.791393750000001 0.8996354166666667 8.299597916666668 1.0181333333333333 8.811875C1.1355770833333334 9.319839583333334 1.3277229166666669 10.100689583333333 1.6375041666666668 11.256775000000001C1.9472854166666669 12.412908333333334 2.171295833333333 13.185181250000001 2.323575 13.683850000000001C2.4771 14.186735416666666 2.9108416666666668 14.523158333333335 3.4259458333333335 14.417741666666668C3.529397916666667 14.396610416666668 3.6428645833333335 14.370016666666666 3.7665854166666666 14.336858333333334C3.8903541666666666 14.3037 4.001904166666667 14.269966666666667 4.10205 14.23656875C4.600910416666667 14.070297916666668 4.808293750000001 13.56209375 4.689795833333333 13.04976875C4.572352083333333 12.541804166666667 4.380158333333333 11.761002083333333 4.070425 10.604868750000001C3.7606437500000003 9.448783333333335 3.5366333333333335 8.676510416666668 3.3843541666666668 8.177793750000001Z" strokeWidth="1"></path>
                <path id="Vector 1661" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.857656250000002 21.846597916666667L13.88754375 13.283027083333334L17.917479166666666 21.846597916666667" strokeWidth="1"></path>
              </g>
            </svg>
            <span className={"menu-btn-text"}>Create New Programmatic SEO Project</span>
          </div>
        </Link>
        <hr className="horizontal-rule" />
        <h2>Your Programmatic SEO Projects</h2>
        <div className="seo-project-abun-table">
          <div className={"table-container"}>
            {
              (isFetching || isLoading) ?
                <div className={"loadingDataCard card mt-4"} style={{ width: "100%" }}>
                  <div className={"card-content"}>
                    <div className={"content is-flex is-justify-content-center"}>
                      <p style={{ textAlign: "center", fontSize: "1.3rem" }}>
                        Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
                      </p>
                    </div>
                  </div>
                </div> :
                <AbunTable tableContentName={"SEO Projects"}
                  tableData={titleProjects}
                  columnDefs={columnDefs}
                  pageSizes={pageSizes}
                  initialPageSize={pageSizes[6]}
                  noDataText={"No Projects Found."}
                  searchboxPlaceholderText={"Search keywords projects..."}
                  handleRowClick={(row, event) => {
                    navigate(`/list-of-titles/${row.project_id}/titles/${row.keywordHash}`);
                  }
                  }
                />
            }
          </div>
        </div>
      </div>
    </>
  )
};


export default TitleProject;