import React, { useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import './Glossary.scss';
import GenericButton from '../../components/GenericButton/GenericButton';
import { useMutation } from '@tanstack/react-query';
import { glossaryMutation } from '../../utils/api';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import AbunModal from '../../components/AbunModal/AbunModal';


const Glossary: React.FC = () => {
    const [word, setWord] = useState('');
    const [loadingWord, setLoadingWord] = useState(false);
    const [proceedEnabled, setProceedEnabled] = useState(false);
    const [requestModalActive, setRequestModalActive] = useState(false);
    const navigate = useNavigate();

    const glossaryMut = useMutation(glossaryMutation);

    // --------------------------- Refs ---------------------------
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);

    const handleGenerateGlossary = () => {
        if (!word.trim()) return;
        setRequestModalActive(true);
        setLoadingWord(true);
        // Trigger the mutation
        glossaryMut.mutate(
            { word },
            {
                onSuccess: (response) => {
                    setRequestModalActive(false);
                    const { project_name, count, project_id } = response.data;
                    successAlertRef.current?.show('Glossary Words generated successfully!');
                    navigate(`/glossary-generator/${project_id}`);
                },
                onError: () => {
                    setRequestModalActive(false);
                    errorAlertRef.current?.show(
                        'Oops! Something went wrong. Please try again or contact support if the issue persists.'
                    );
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 5000);
                },
                onSettled: () => {
                    setLoadingWord(false);
                },
            }
        );
    };


    function goBack() {
        navigate(-1);
    }

    // Track input changes and update word state
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newWord = e.target.value;
        setWord(newWord);

        // Enable the Proceed button when there is input
        setProceedEnabled(newWord.trim().length > 0);
    };

    return (
        <div className="glossary-card">
            <span className={"back-btn"} onClick={goBack}>
                <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                </svg>
            </span>
            <h1 className={"is-size-4"} >Glossary Article Creator</h1>
            <div className="glossary-content">
                <h1 className="glossary-subtitle">Enter a Topic for Create a Glossary List</h1>
                <p className="glossary-description">
                    Example, "Graphic Design" generates a list of niche-relevant terms.<br />
                    Approve the terms, then create & post content.
                </p>
                <div className="glossary-input-container">
                    <input
                        type="text"
                        placeholder="Topic"
                        className="glossary-input"
                        value={word}
                        onChange={handleInputChange}
                    />

                </div>
                <GenericButton
                    text={"PROCEED"}
                    type="success"
                    additionalClassList={["mt-5"]}
                    clickHandler={handleGenerateGlossary}
                    disable={!proceedEnabled}
                />
            </div>

            {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
            <AbunModal active={requestModalActive}
                headerText={""}
                modelWidth="450px"
                closeable={false}
                hideModal={() => {
                    setRequestModalActive(false)
                }}
                backgroundClass="lg-tail-kw-modal-background"
                modalCardClass="lg-tail-kw-abun-modal-card"
            >
                <div className="loader-container" aria-live="polite" aria-busy={loadingWord}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '30px'
                    }}>
                    {/* ldrs loader */}
                    <l-quantum size="60" speed="1.75" color="#3F77F8"></l-quantum>
                </div>
                <h3 className="modal-header-text">Please hold on</h3>
                <p className="modal-subtext">Processing request. Please wait...</p>
            </AbunModal>

            <SuccessAlert ref={successAlertRef} />
            <ErrorAlert ref={errorAlertRef} />

        </div>
    );
};

export default Glossary;
